var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{staticClass:"project"},[_c('section',{staticClass:"bg-img",style:({
        backgroundImage:
          'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,.9) 20%, rgba(255,255,255,0) 100%), url(' +
          require(("@assets/images/" + (_vm.project.coverImage))) +
          ')',
      })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"project__logo"},[_c('a',{attrs:{"href":_vm.project.website,"target":"_blank"}},[_c('img',{attrs:{"src":require(("@assets/images/" + (_vm.project.logoWhite))),"alt":("Logo " + (_vm.project.name))}})])])])]),_c('section',{staticClass:"padder-50"},[_c('div',{staticClass:"container"},[_c('div',[_c('h3',{staticClass:"project-name"},[_vm._v(_vm._s(_vm.project.name))]),_c('div',{staticClass:"project-info"},[_c('p',[_vm._v(_vm._s(_vm.project.subtitle))]),_c('p',{staticClass:"text-right text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.project.date))])])])]),_c('div',{staticClass:"text-center p-5"},[_c('img',{attrs:{"src":require(("@assets/images/" + (_vm.project.screenshot))),"alt":"Project Screenshot 1"}})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 pr-5 d-flex justify-content-center flex-column"},[_c('h3',[_vm._v(_vm._s(_vm.project.mobile.title))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.project.mobile.description)}}),_c('a',{staticClass:"btn",attrs:{"href":_vm.project.website,"target":"_blank"}},[_c('span',{staticClass:"circle bg-color-green"}),_c('span',{staticClass:"button-text"},[_vm._v("Visiter le site web +")])])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('img',{attrs:{"src":require(("@assets/images/" + (_vm.project.mobile.image))),"alt":"Project mobile screenshot"}})])])])]),_c('section',{staticClass:"text-center position-relative"},[_c('div',{staticClass:"position-absolute bg-img",style:({
          backgroundImage:
            'linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,1) 90%), url(' +
            require(("@assets/images/" + (_vm.project.coverCenterImage))) +
            ')',
        })}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"project__padder"},[_c('h3',[_vm._v("Notre Mission")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.project.mission)}}),_c('a',{staticClass:"btn",attrs:{"href":_vm.project.briefLink}},[_c('span',{staticClass:"circle bg-color-purple"}),_c('span',{staticClass:"button-text"},[_vm._v("Briefez-nous")])])])])]),_c('section',{staticClass:"padder-50"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Clin d'œil sur nos réalisations")])]),_c('div',[_c('img',{staticClass:"project__devices",attrs:{"src":require(("@assets/images/" + (_vm.project.devicesImage))),"alt":"Screenshot on different devices"}})])])]),_c('section',{staticClass:"padder-50"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require(("@assets/images/" + (_vm.project.screenshotFull))),"alt":"Project Screenshot 2"}})])]),_c('section',{staticClass:"padder-50"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"mt-5"},[_c('h4',{staticClass:"text-uppercase project-name mb-0"},[_vm._v("Voir plus de projets")]),_c('h1',{staticClass:"mt-0"},[_vm._v("Voir plus de projets")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('ArticleComponent',_vm._b({},'ArticleComponent',_vm.otherProjects[0],false))],1),_c('div',{staticClass:"col-12 col-md-6 mt-5"},[_c('ArticleComponent',_vm._b({},'ArticleComponent',_vm.otherProjects[1],false))],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }