/* eslint-disable vue/no-v-html */
<template>
  <Layout class="project">
    <section
      :style="{
        backgroundImage:
          'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,.9) 20%, rgba(255,255,255,0) 100%), url(' +
          require(`@assets/images/${project.coverImage}`) +
          ')',
      }"
      class="bg-img"
    >
      <div class="container">
        <div class="project__logo">
          <a :href="project.website" target="_blank">
            <img
              :src="require(`@assets/images/${project.logoWhite}`)"
              :alt="`Logo ${project.name}`"
            />
          </a>
        </div>
      </div>
    </section>

    <section class="padder-50">
      <div class="container">
        <div>
          <h3 class="project-name">{{ project.name }}</h3>
          <div class="project-info">
            <p>{{ project.subtitle }}</p>
            <p class="text-right text-uppercase">
              <b>{{ project.date }}</b>
            </p>
          </div>
        </div>
        <div class="text-center p-5">
          <img
            :src="require(`@assets/images/${project.screenshot}`)"
            alt="Project Screenshot 1"
          />
        </div>

        <div class="row">
          <div
            class="col-12 col-md-6 pr-5 d-flex justify-content-center flex-column"
          >
            <h3>{{ project.mobile.title }}</h3>
            <p v-html="project.mobile.description"></p>
            <a :href="project.website" target="_blank" class="btn">
              <span class="circle bg-color-green"></span>
              <span class="button-text">Visiter le site web +</span>
            </a>
          </div>
          <div class="col-12 col-md-6">
            <img
              :src="require(`@assets/images/${project.mobile.image}`)"
              alt="Project mobile screenshot"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="text-center position-relative">
      <div
        class="position-absolute bg-img"
        :style="{
          backgroundImage:
            'linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,1) 90%), url(' +
            require(`@assets/images/${project.coverCenterImage}`) +
            ')',
        }"
      ></div>
      <div class="container">
        <div class="project__padder">
          <!-- <img :src="require(`@assets/images/${project.coverCenterImage}`)" alt="Cover Image" class="cover cover-center"> -->
          <h3>Notre Mission</h3>
          <p v-html="project.mission"></p>
          <a :href="project.briefLink" class="btn">
            <span class="circle bg-color-purple"></span>
            <span class="button-text">Briefez-nous</span>
          </a>
        </div>
      </div>
    </section>

    <section class="padder-50">
      <div class="container">
        <div class="text-center">
          <h3>Clin d'œil sur nos réalisations</h3>
        </div>
        <div>
          <img
            :src="require(`@assets/images/${project.devicesImage}`)"
            alt="Screenshot on different devices"
            class="project__devices"
          />
        </div>
      </div>
    </section>

    <!-- <section class="padder-50">
      <div class="container">
        <div class="row mb-5 typo">
          <div class="col-12 col-md-6 pr-5 typo-description">
            <h3>Typographie</h3>
            <p>{{ project.typo.description }}</p>
          </div>
          <div
            class="col-12 col-md-6"
            :style="{ fontFamily: project.typo.font }"
          >
            <div class="mb-4">
               <h1 class="typo__text">Lorem Ipsum</h1>
              <h1 class="typo__text">Aa Bb Cc 0 1 2</h1>
            </div>
            <div>
               <h1 class="typo__text">Lorem Ipsum</h1>
              <h1 class="typo__text">Aa Bb Cc 0 1 2</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="padder-50">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 col-md-6 colors">
            <div
              v-for="(color, i) in project.colors.colorCodes"
              :key="i"
              class="text-center px-4"
            >
              <span
                :style="{ backgroundColor: color }"
                class="color-circle"
              ></span>
              <p>{{ color }}</p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <h3>Couleurs</h3>
            <p>{{ project.colors.description }}</p>
          </div>
        </div>
      </div>
    </section>
-->
    <section class="padder-50">
      <div class="container">
        <img
          :src="require(`@assets/images/${project.screenshotFull}`)"
          alt="Project Screenshot 2"
        />
      </div>
    </section>

    <section class="padder-50">
      <div class="container">
        <div class="mt-5">
          <h4 class="text-uppercase project-name mb-0">Voir plus de projets</h4>
          <h1 class="mt-0">Voir plus de projets</h1>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <ArticleComponent v-bind="otherProjects[0]" />
          </div>
          <div class="col-12 col-md-6 mt-5">
            <ArticleComponent v-bind="otherProjects[1]" />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from '@layouts/main.vue'
import ArticleComponent from '@components/article-component.vue'
import { projectsComputed } from '@state/helpers'
export default {
  // page: {
  //   tite: this.meta.title,
  //   meta: [
  //     {
  //       vmid: 'description',
  //       name: 'description',
  //       content: 'this.meta.metaInfo.description',
  //     },
  //   ],
  // },
  components: { Layout, ArticleComponent },
  data: () => {
    return {
      meta: {
        title: '',
      },
    }
  },
  computed: {
    ...projectsComputed,
  },
  created() {
    // look up the project
    this.project = this.projects.find(
      (project) => project.path === this.$route.params.projet
    )
    this.meta = this.project.metaInfo

    // if it exists, proceed
    if (!this.project) {
      this.$router.push('/404')
    }
    this.otherProjects = this.getNRandomProjects()
  },

  mounted() {
    document.title = this.project.metaInfo.title
    var meta = document.createElement('meta')
    meta.name = 'description'
    meta.content = this.project.metaInfo.description
    document.getElementsByTagName('head')[0].appendChild(meta)
  },

  methods: {
    getNRandomProjects(n = 2) {
      const selectedProjects = this.projects.filter(
        (p) => p.path !== this.$route.params.projet
      )
      if (selectedProjects.length <= n) return selectedProjects
      while (selectedProjects.length > n) {
        const randomIndex = Math.floor(Math.random() * selectedProjects.length)
        selectedProjects.splice(randomIndex, 1)
      }
      return selectedProjects
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
$circle-diameter: 50px;
.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
img {
  max-width: 100%;
}
.project {
  .bg-img {
    top: -30%;
    right: 0;
    bottom: -30%;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    background-size: cover;
  }
  &__padder {
    padding: 80px 0;
  }
  &-name {
    color: $color-theme-green;
  }
  &-info {
    display: flex;
    justify-content: space-between;
  }
  &__logo {
    padding: 200px 0;
    text-align: center;
    img {
      max-width: 200px;
    }
    a {
      position: relative;
      display: inline-block;
    }
  }
  .typo {
    font-size: $size-font-large;
    &-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__text {
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: 120%;
    }
  }
  .colors {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .color-circle {
    display: inline-block;
    width: $circle-diameter;
    height: $circle-diameter;
    border-radius: 50%;
  }
}
</style>
